export default {
    //API_URL: "http://fastshipping.proderyazilim.com/api/",
    //API_URL: "http://kargo.ersankargo.com/api/",
    //API_URL: "http://acente.pointkargo.com/api/",
    //API_URL: "http://poshta.org/api/",
    //API_URL: "http://kargoportali.com/api/",
    //API_URL: "http://kargo.prokargo.net/api/",
    //API_URL: "http://kurumsal.enexpresskargo.com.tr/api/",
    //API_URL: "http://acente.iyikargo.com/api/",
    //API_URL: "http://point.proderyazilim.com/api/",
    //API_URL: "http://express.nbskargo.com/api/",
    //API_URL: "http://kargo.expresskargo.com.tr/api/",
    //API_URL: "http://kanal.octopuslojistik.com/api/",
    // API_URL: "http://192.168.18.4:88/",

    API_URL: "https://epsistem.com/api/",
    // API_URL: "http://narkargo.com/api/",
    // API_URL: "http://cargo.local/",

    //SMS_API_URL: "http://poshta.org/apix/",
    SMS_API_URL: "",
    //SMS_API_URL: "http://192.168.1.50/poshta/",

    USE_MAP_API: false,
    MAP_API_KEY: "",
    //MAP_API_KEY: "AIzaSyBypaSmPsNgxBddTxE_Zp4nwe_yauFv1UU",

    USE_PARSED_NAME: false,

    USE_COUNTRY: false,
    USE_REGION: false,
    //USE_INVOICE: false,

    USE_REGION_PRICE: false,
    USE_INSURANCE_PAY: false,
    USE_BOX_PRICE: false,

    POSTA_SERVICE_COST: 0,
    USE_DISCOUNT_ALL: false,
    INSURANCE_RATE: 0,

    USE_CONCAT_ADDRESS: false,

    USE_MULTI_READ_BARCODE: true,

    USE_ANOTHER_INVOICE: true,

    USE_PIECE_MULTIPLICATION: false,

    USE_GMAP_API: true,
};
